@use 'src/styles/utility' as *;
#app-manager-dashboard {
  table {
    border-spacing: 0;
    display: table;
    min-width: 765px;
    width: 100%;
  }

  thead {
    background-color: var(--ifm-color-emphasis-100);
    border: 3px solid var(--ifm-color-emphasis-100);
    height: rem(5);
  }

  th {
    min-width: rem(20);
  }

  th,
  td {
    display: table-cell;
    border-left: none;
    border-right: none;
    border-bottom-color: var(--ifm-color-emphasis-200);
    text-align: left;
    &:last-child {
      text-align: center;
    }
  }

  td:nth-child(3) {
    max-width: rem(20);
    min-width: rem(15);
  }

  td:last-child,
  th:last-child {
    white-space: nowrap;
    padding: 0 rem(1);
  }

  td:first-child,
  th:first-child {
    padding-left: rem(3.2);
    white-space: break-spaces;
  }

  tbody tr {
    display: table-row;
    border: none;
    background-color: var(--ifm-color-emphasis-0);
  }
}

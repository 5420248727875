.dashboard-spinner {
  height: 90vh;
}

.breadcrumbs-wrapper {
  padding: 1rem 0;

  a, a:active, a:visited {
    text-decoration: none;
  }
}

@use 'src/styles/mixins' as *;

.tabs {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-family: var(--ibm-font-family-base);
  font-weight: var(--ifm-font-weight-semibold);
  overflow-x: visible;

  &_header {
    margin-block: 45px;
    background-color: var(--opacity-black-75);
    padding: 12px;
    border-radius: 24px;
    text-align: center;

    &__items {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &__item {
      padding: 12px 18px;
      min-width: 320px;
      cursor: pointer;

      @include mobile-sm {
        min-width: 160px;
      }

      &.active {
        background-color: var(--solid-slate-50);
        border-radius: 12px;
      }
    }

    @include mobile {
      margin-block: 32px;
    }
  }
  &__content {
    width: 100%;
  }
}

@use 'src/styles/utility' as *;

.update_form {
  display: flex;
  justify-content: center;

  .formContent {
    display: flex;
    flex-direction: column;
    margin-inline: 16px;
    max-width: 608px;
  }

  .uri_input {
    margin-block: 4px;
  }

  &__oauth_info {
    list-style: disc;
    line-height: 24px;
    margin: 0;
  }

  &__fields_button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: rem(0.8);
  }

  .mblk {
    margin-block: 24px;
  }

  .explanation {
    color: var(--core-color-opacity-black-400, rgba(0, 0, 0, 0.48));
  }

  .mb_sm {
    display: flex;
    flex-direction: column;
    gap: rem(0.8);
    margin-bottom: 8px;
  }

  .mb_md {
    margin-bottom: 16px;
  }

  .mb_lg {
    margin-bottom: 24px;
  }

  .mt_sm {
    margin-top: 8px;
  }

  .mt_md {
    margin-top: 16px;
  }

  .mt_lg {
    margin-top: 48px;
  }

  .mst {
    margin-top: 24px;
  }

  .mt {
    margin-top: 40px;
  }

  .scopes_mt {
    margin-top: 48px;
  }

  b {
    font-weight: bold;
  }
}

.scopes {
  display: flex;
  flex-direction: column;
  gap: rem(1);
}

.scopesWrapper {
  display: flex;
  flex-direction: column;
  gap: rem(0.8);
}

.scopeItem {
  border: 1.6px solid var(--ifm-color-emphasis-800);
  border-radius: 6.4px;
  padding: rem(1.28) rem(0.64);
}

.customCheckboxWrapper {
  display: flex;
  font-size: medium;

  label {
    cursor: pointer;
  }

  @media screen and (max-width: 992px) {
    label {
      margin-top: -6px;
    }
  }
}

.underlined_link {
  text-decoration: underline;
  cursor: pointer;
  color: var(--component-textIcon-normal-default);
  font-family: var(--semantic-typography-body-md-regular-default-fontFamily);
  font-size: var(--semantic-typography-body-md-regular-default-fontSize);
  font-style: var(--semantic-typography-body-md-regular-default-fontWeight);
  font-weight: var(--semantic-typography-body-md-regular-default-fontWeight);
  line-height: var(--semantic-typography-body-md-regular-default-lineHeight);
  letter-spacing: var(--semantic-typography-body-md-regular-default-letterSpacing);
}

.admin-scope-modal {
  .quill-button {
    display: grid;
    max-width: 416px;
    @media (max-width: 720px) {
      max-width: 575px;
    }
  }
}

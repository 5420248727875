.tooltip_content {
  border-radius: 4px;
  padding: 12px;
  font-size: 12px;
  line-height: 14px;
  color: var(--ifm-color-emphasis-100);
  background-color: var(--ifm-color-emphasis-700);
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  user-select: none;
  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;
  max-width: 200px;
  text-align: center;
}

.tooltip_arrow {
  fill: var(--ifm-color-emphasis-700);
}

div[data-radix-popper-content-wrapper] {
  z-index: 9999 !important;
}
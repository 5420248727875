.align-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.quill-modal__button-wrapper {
  padding: 24px 32px;
}

.quill-button__full-width {
  width: 92%;
}

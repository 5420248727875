:root {
  --icon-color: #c47d00;
}

.align-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.token_register__scopes__text {
  align-self: flex-start;
}

.token_register__container {
  @extend .align-center;

  .formContent {
    @extend .align-center;
    padding: 0 16px;
    width: 100%;
    max-width: 608px;
  }

  .token_register__inputfield {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 4px !important;
  }

  .token_register__heading {
    text-align: center;
    margin-bottom: 1.5rem;
  }

  .token_register__account__switcher {
    @media screen and (min-width: 501px) {
      max-width: 360px;
    }
  }

  .token_register__account {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    z-index: 10;
    margin-bottom: 1.5rem;
  }
  .token_register__scopes,
  .token_register__name {
    width: 100%;
  }

  .demo_checkbox {
    margin-bottom: 8px;
  }

  .token_register__scopes__container {
    width: 48%;
    padding: 16px;
    border-radius: 16px;
    border: 1px solid var(--core-color-opacity-black-100, rgba(0, 0, 0, 0.08));

    @media (max-width: 432px) {
      width: 100%;
    }
  }
  .token_register__scopes {
    display: flex !important;
    justify-content: flex-start !important;
    flex-wrap: wrap !important;
    gap: 16px;
    justify-content: space-around;
    .customCheckboxWrapper {
      margin-bottom: 16px;

      label {
        cursor: pointer;
      }
    }
  }

  .token_register__restrictions {
    list-style: disc;
    line-height: 24px;
    color: var(--colors-coral500);
  }

  .token_register__actions {
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      margin-inline: 8px;
    }
  }

  .card_wrapper {
    display: grid;
    grid-template-columns: 0.5fr 0.5fr;
    grid-gap: 1.6rem;
    margin: 1.5rem 0;
    @media screen and (max-width: 765px) {
      grid-template-columns: 1fr;
    }
  }

  .mblk {
    margin-block: 16px;
  }

  .mt {
    margin-bottom: 16px;
  }

  .mb {
    margin-bottom: 16px;
  }

  b {
    font-weight: bold;
  }

  li {
    color: var(--core-color-opacity-black-400, rgba(0, 0, 0, 0.48));
    font-size: 12px;
  }

  .admin-scope-modal .quill_modal__dialog {
    max-width: 560px;
  }
}

@use 'src/styles/utility' as *;
@use 'src/styles/mixins' as *;
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@400;500;700&family=Ubuntu:wght@400;500;700&display=swap');

/**
 * Any CSS included here will be global. The classic template
 * bundles Infima by default. Infima is a CSS framework designed to
 * work well for content-centric websites.
 */
/* You can override the default Infima variables here. */
/* Note: !important used to override node_modules css classes 
   in order to avoid swizzling the whole component just to modify few classes */

:root {
  --ibm-font-family-base: 'IBM Plex Sans', sans-serif;
  --ubuntu-font-family: 'Ubuntu', sans-serif;
  --ifm-color-primary: #2e8555;
  --ifm-color-primary-dark: #29784c;
  --ifm-color-primary-darker: #277148;
  --ifm-color-primary-darkest: #205d3b;
  --ifm-color-primary-light: #33925d;
  --ifm-color-primary-lighter: #359962;
  --ifm-color-primary-lightest: #3cad6e;
  --ifm-navbar-sidebar-width: 100%;
  --ifm-code-font-size: 95%;
  --text-profit-success: #4bb4b3;
  --demo-account: #377cfc;
  --docusaurus-highlighted-code-line-bg: rgba(0, 0, 0, 0.1);
  --nav-height: 72px;
  --ifm-menu-color: #414652;
  --ifm-menu-link-sublist-icon-2: url(/img/arrow_sidebar.svg);
  --ifm-menu-link-padding-vertical: 9px;
  --doc-sidebar-width: 283px !important;
  --search-result: #ff9ba3;
  --schema-string: #21d169;
  --schema-array: #ff8fc8;
  --schema-number: #acb2ff;
  --schema-integer: #f8c272;
  --schema-null: #ff444f;
  --spinner-height: 100px;
  --spinner-width: 100px;
  --smoke: #414652;
  --admin-text: #22bd41;
  --admin-border: #33c9517a;
  --solid-slate-50: #ffffff;
  --solid-slate-75: #f6f7f8;
  --opacity-black-100: #00000014;
  --opacity-black-75: #0000000a;
  --hover-solid-light-100: #f6f7f8;

  --validation-text-light: #0000007a;
}

/* For readability concerns, you should choose a lighter palette in dark mode. */
[data-theme='dark'] {
  --ifm-color-primary: #25c2a0;
  --ifm-color-primary-dark: #21af90;
  --ifm-color-primary-darker: #1fa588;
  --ifm-color-primary-darkest: #1a8870;
  --ifm-color-primary-light: #29d5b0;
  --ifm-color-primary-lighter: #32d8b4;
  --ifm-color-primary-lightest: #4fddbf;
  --docusaurus-highlighted-code-line-bg: rgba(0, 0, 0, 0.3);
}

// Be very careful adding new things to the star selector.
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font: var(--ifm-font-size-base) / var(--ifm-line-height-base) var(--ifm-font-family-base) !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
a,
button {
  font-family: var(--ubuntu-font-family);
}

ul li a,
nav a,
nav a div {
  font-family: var(--ibm-font-family-base);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
}

h4 {
  @media screen and (max-width: 993px) {
    font-size: rem(1.4);
  }
}

.align-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.error-message {
  color: var(--colors-coral500) !important;
  margin-left: rem(1.5);
  font-size: var(--fontSizes-2xs) !important;
  display: inline-block;
  align-self: flex-start;
}

.error-border {
  border-color: var(--colors-coral500) !important;
  &:focus-within {
    border-color: var(--colors-coral500) !important;
  }
  &:focus {
    outline: var(--colors-coral500) !important;
  }
}

.error-border {
  border-color: var(--colors-coral500) !important;
  &:focus-within {
    border-color: var(--colors-coral500) !important;
  }
  &:focus {
    outline: var(--colors-coral500) !important;
  }
  label {
    color: var(--colors-coral500) !important;
  }
}

/* reset */
button {
  all: unset;
}

a {
  color: var(--colors-coral500);
  &:hover {
    color: var(--colors-coral500);
  }
}

.codeBlockContent {
  font-size: 15.2px;
  font-family: var(--ibm-font-family-base);
}

code {
  font-size: 15.2px !important;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

h1,
h2 {
  font-family: var(--ubuntu-font-family);
  hyphens: auto;
}

html,
body {
  overflow-x: hidden;
  overflow-anchor: none;
}

.hidden {
  display: none !important;
}

.unofficial-host {
  .hide-sidebar-item {
    display: none !important;
  }
  .navbar__items--right {
    display: none !important;
  }
}

.swiper {
  padding: 0 rem(4) !important;
  width: rem(32) !important;
  @media screen and (min-width: 600px) {
    width: rem(55) !important;
  }
}

div[class*='admonitionHeading'] {
  font-family: var(--ubuntu-font-family);
}

main[class*='docMainContainer'] .container {
  padding-top: rem(4) !important; // Have to use important since docusaurus does the same.
  margin: 0 rem(4);
}

div[class*='sidebarViewport'] {
  position: fixed;
  svg[class*='expandButtonIcon'] {
    position: absolute;
    left: rem(0.5);
  }
}

.quill-modal {
  &__content-wrapper {
    overflow: auto !important;
  }
}

.quill-textarea {
  &__container {
    width: 100% !important;
  }
}

.modal-overlay {
  > div {
    z-index: 1000;
  }
  div[role='dialog'] {
    z-index: 1001;
    border-radius: rem(1.6);
  }
}

.modal__icon {
  @extend .align-center;
  padding: 24px;
}

.modal__text {
  padding: 24px 32px;
  gap: 32px;
}

.modal__header {
  padding-bottom: 32px;
  font-size: 18px;
}

.modal__content {
  padding: 24px 32px;

  h4 {
    margin-bottom: 32px;
  }

  ul {
    padding-top: 16px;
    li {
      list-style: disc;
    }
  }
}

.loading {
  position: absolute;
  width: 100%;
  height: fit-content;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-wrapper {
  min-height: calc(100vh - 4.5rem);
  &.home_page_wrapper {
    height: 100%;
  }
}

.navbar {
  padding: 0;
  height: var(--nav-height);
  line-height: rem(2);
  @media (max-width: 996px) {
    padding: 0 rem(1.5);
  }
  .right-navigation {
    display: flex;
    align-items: center;
    gap: rem(1);
  }
  &__inner {
    @media screen and (min-width: 1440px) {
      margin: auto;
    }

    .navbar__items:first-child a {
      font-weight: normal;
    }
  }
  &__link {
    color: var(--core-color-solid-slate-700);
    border-radius: var(--core-borderRadius-600);
    padding: 1% 2%;

    @include tablet-lg {
      padding: rem(1.3);
    }

    &:hover {
      color: rgba(0, 0, 0, 72%);
      background-color: var(--hover-solid-light-100);
      text-decoration: none;
    }

    &--active {
      color: var(--colors-coral500);
    }
  }
  &__brand {
    margin-right: rem(10);

    @media screen and (max-width: 1440px) {
      margin-right: rem(4);
    }
  }
  &__logo {
    width: rem(12);
  }
  &__item {
    font-size: rem(1.4);
  }

  .external-nav-link {
    &::after {
      content: '';
      display: inline-block;
      position: relative;
      bottom: 2px;
      background-image: url('/img/arrow_up_right.svg');
      background-repeat: no-repeat;
      background-size: rem(1.1);
      margin-left: rem(0.5);
      width: rem(1.6);
      height: rem(1.6);

      @media screen and (max-width: 996px) {
        height: rem(2);
        background-size: 0.8rem;
        margin-left: 0;
        right: 3px;
        bottom: 6px;
      }
    }
  }

  .navbar-sidebar {
    padding-top: 0.5rem;
    width: 100%;
    transform: translate3d(0, -100%, 0);
    transition-duration: var(--ifm-transition-slow);

    ul > div {
      display: none;
    }

    &__item {
      width: 100%;
    }

    &__brand {
      box-shadow: none;
      padding: 0 rem(2.5) 0 rem(1.5);

      .clean-btn {
        svg {
          width: 18px;
        }
      }
    }
  }
}

.navbar-sidebar--show {
  .navbar-sidebar {
    transform: translate3d(0, 0, 0);
  }
}

.navbar__items--right {
  gap: rem(1);
  a {
    font-weight: 700;
  }
}

main[class*='docMainContainer'] .container {
  padding-top: rem(4) !important; // Have to use important since docusaurus does the same.
  margin: 0 rem(4);
  @media (max-width: 768px) {
    margin: 0;
  }
}

.theme-doc-sidebar-container .theme-doc-sidebar-menu {
  margin-top: rem(9);
}

.theme-doc-markdown {
  font-family: var(--ibm-font-family-base);
}

.menu__link {
  font-size: rem(1.4);
  font-weight: var(--ifm-font-weight-normal);
  line-height: rem(2);
}

.menu__caret:before {
  background: var(--ifm-menu-link-sublist-icon-2);
  filter: var(--ifm-menu-link-sublist-icon-filter);
  height: rem(1.2);
  transform: rotateZ(90deg);
  width: rem(0.872);
}

.menu__list-item--collapsed .menu__link--sublist:after,
.menu__list-item--collapsed .menu__caret:before {
  transform: rotateZ(0deg);
}

.menu__link--active,
.menu__link--active:hover {
  color: var(--colors-coral500);
}

.menu__caret {
  margin-right: rem(3.3);
}

[class*='tocCollapsibleButton'] {
  box-sizing: border-box;
  &::after {
    background: var(--ifm-menu-link-sublist-icon-2) !important;
    height: rem(1.2) !important;
    width: rem(0.872) !important;
    transform: rotate(90deg) !important;
  }
}

[class*='tocCollapsibleButtonExpanded']::after {
  transform: rotate(270deg) !important;
}

.json-viewer-theme-dark {
  overflow-x: scroll;
}

@media (max-width: 768px) {
  .navbar__logo {
    width: rem(9);
  }

  .navbar__toggle {
    width: rem(2.2);
  }
}

@use 'src/styles/utility' as *;

.language_switcher {
  .dropdown > .navbar__link:after {
    display: none;
  }

  a.navbar__link {
    color: var(--core-color-solid-slate-1400, #000) !important;
    display: inline-block;
  }

  .navbar__item {
    padding: 0;
  }

  .dropdown__menu {
    padding: 0 15px 12px;

    li {
      cursor: pointer;

      a:hover {
        background-color: var(--hover-solid-light-100);
        border-radius: 10px;
      }
    }
  }

  .localeItem {
    display: flex;
    gap: 10px;
    flex-direction: row;
    align-items: center;
    padding-top: 4px;
  }

  .navbar__link {
    font-weight: normal;
  }

  .dropdown__link {
    font-size: 16px;
    font-weight: normal;
    margin-top: 15px;
    padding: 10px;
    color: var(--core-color-opacity-black-600, rgba(0, 0, 0, 0.72)) !important;
  }

  .dropdown__link--active {
    color: var(--colors-coral500) !important;
    background-color: var(--ifm-navbar-background-color);
  }
}

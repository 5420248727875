@mixin mobile-sm {
  @media screen and (max-width: 550px) {
    @content;
  }
}

@mixin mobile {
  @media screen and (max-width: 768px) {
    @content;
  }
}

@mixin tablet {
  @media screen and (max-width: 992px) {
    @content;
  }
}

@mixin tablet-lg {
  @media screen and (max-width: 1024px) {
    @content;
  }
}

@mixin desktop {
  @media screen and (min-width: 1025px) {
    @content;
  }
}
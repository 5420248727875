@use 'src/styles/mixins' as *;

.accordion_root {
  margin: 16px;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;

  @include mobile {
    margin-top: 15px;
  }

  @include mobile-sm {
    margin: 0;
    margin-top: 15px;
  }

  &__item {
    overflow: hidden;
    margin-top: 2px;
    border-radius: 24px;
  }
}

.accordion_header {
  display: flex;
  overflow-wrap: anywhere;
  column-gap: 12px;
  background-color: transparent;

  [data-state='open'] {
    background-color: var(--opacity-black-75);
    padding: 16px;
  }

  &__trigger {
    font-family: inherit;
    padding: 24px 0px;
    height: 42px;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    line-height: 1.5;
    font-weight: 400;

    @media screen and (max-width: 786px) {
      padding: 24px 15px;
    }
  }

  .accordion_chevron {
    transition: transform 300ms cubic-bezier(0.87, 0, 0.13, 1);
  }

  [data-state='open'] > .accordion_chevron {
    transform: rotate(180deg);
  }
}

.accordion_content {
  overflow: hidden;
  background-color: var(--opacity-black-75);

  &__text {
    padding: 16px 18px;
    font-size: 14px;
    font-weight: 400;
  }

  &[data-state='open'] {
    animation: slideDown 300ms cubic-bezier(0.87, 0, 0.13, 1);
  }

  &[data-state='closed'] {
    animation: slideUp 300ms cubic-bezier(0.87, 0, 0.13, 1);
    background-color: transparent;
  }
}

@keyframes slideDown {
  from {
    height: 0;
  }
  to {
    height: var(--radix-accordion-content-height);
  }
}

@keyframes slideUp {
  from {
    height: var(--radix-accordion-content-height);
  }
  to {
    height: 0;
  }
}
